<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-row
          class="ml-2"
        >
          <v-col
            cols="5"
            md="2"
          >
            <v-select
              v-model="mes"
              :label="$t('padrao_mes')"
              :items="listagemMeses"
              prepend-icon="mdi-format-list-bulleted-type"
            />
          </v-col>
          <v-col
            cols="5"
            md="2"
          >
            <v-select
              v-model="ano"
              :label="$t('padrao_ano')"
              :items="listagemAnos"
              prepend-icon="mdi-format-list-bulleted-type"
            />
          </v-col>
          <v-btn
            class="v-btn--simple mt-5"
            color="primary"
            outlined
            :icon="$vuetify.breakpoint.sm"
            @click="preencherDados"
          >
            <span v-if="!$vuetify.breakpoint.sm">{{ $t('padrao_pesquisar') }}</span>
            <v-icon>{{ 'mdi-magnify' }}</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="primary"
          :title="$t('EventosForm.proximosEventos')"
          :text="$t('EventosForm.dezProximosEventos')"
        >
          <v-data-table
            :headers="proximosEventosCabecalho"
            :items="proximosEventos"
            :loading="loadingProximosEventos"
            hide-default-footer
            :hide-default-header="true"
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="font-weight-light text-warning text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <tr>
                <!--
                <td
                  class="hidden-sm-and-down justify-left btnEditar cursorPointer"
                  @click="editarEvento(item)"
                >
                  {{ item.tipoEvento }}
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </td>
                <td class="hidden-sm-and-down justify-left">
                  {{ item.nomeCliente }}
                </td>
                <td class="hidden-sm-and-down justify-left">
                  {{ item.nomeClientePagador }}
                </td>
                <td
                  v-data-formatada
                  class="hidden-sm-and-down justify-left"
                >
                  {{ item.dataEvento }}
                </td>
                <td class="hidden-sm-and-down justify-left">
                  {{ item.horaEvento }}
                </td>
                <td class="hidden-sm-and-down justify-left">
                  {{ item.telefone1 }}
                </td>
                -->
                <td
                  class="justify-center"
                  @click="editarEvento(item)"
                >
                  <div class="tipoEvento">
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                    {{ item.tipoEvento }}
                  </div>
                  <div class="nomeCliente">
                    <v-icon small>
                      mdi-database-search
                    </v-icon>
                    {{ item.nomeCliente }}
                  </div>
                  <div class="nomeClientePagador">
                    <v-icon small>
                      mdi-database-search
                    </v-icon>
                    {{ item.nomeClientePagador }}
                  </div>
                  <div class="dataEvento">
                    <v-icon small>
                      mdi-calendar-text
                    </v-icon>
                    <span v-data-formatada>{{ item.dataEvento }}</span>
                    {{ item.horaEvento }}
                  </div>
                  <div class="telefone1">
                    <v-icon
                      v-if="item.telefone1"
                      small
                    >
                      mdi-phone-classic
                    </v-icon>
                    {{ item.telefone1 }}
                  </div>
                </td>
                <td v-if="!idEmpresaCliente">
                  <span
                    v-if="item.EmpresaCliente !== null && item.EmpresaCliente !== undefined"
                  >{{ item.EmpresaCliente.nome }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="#0054db"
          :title="$t('Dashboard.despesas')"
          :text="dataPesquisa"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-counter</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('Dashboard.quantidade_total_despesas') }}</v-list-item-title>
     
            <v-list-item-subtitle class="text-right">
              {{ resumoDespesas.contador }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('Dashboard.valor_total_despesas') }}</v-list-item-title>
            <v-list-item-subtitle
              v-money="{simbolo:'R$', valor:resumoDespesas.valorTotal}"
              class="text-right"
            />
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cash-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('Dashboard.total_despesas_pagas') }}</v-list-item-title>
            <v-list-item-subtitle
              v-money="{simbolo:'R$', valor:resumoDespesas.valorPago}"
              class="text-right"
            >
              {{ resumoDespesas.valorPago }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cash-minus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('Dashboard.total_despesas_nao_pagas') }}</v-list-item-title>
            <v-list-item-subtitle
              v-money="{simbolo:'R$', valor:resumoDespesas.valorNaoPago}"
              class="text-right"
            >
              {{ resumoDespesas.valorNaoPago }}
            </v-list-item-subtitle>
          </v-list-item>
        </material-card>
      </v-col>
      <!--
      <v-col
        md="12"
        sm="12"
        lg="4"
      >
        <material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="info"
          type="Bar"
        >
          <h4 class="title font-weight-light">
            Eventos Futuros
          </h4>
          -->
      <!--
          <p class="category d-inline-flex font-weight-light">
            <v-icon
              color="green"
              small
            >
              mdi-arrow-up
            </v-icon>
            <span class="green--text">55%</span>&nbsp;
            increase in today's sales
          </p>
          -->

      <!-- <template slot="actions">
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">Atualizado a 4 minutos atras</span>
          </template> -->
      <!--
        </material-chart-card>
      </v-col>
      <v-col
        md="12"
        sm="12"
        lg="4"
      >
        <material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="red"
          type="Bar"
        >
          <h4 class="title font-weight-light">
            Valor a receber / Mês do Evento
          </h4>

      -->
      <!-- <p class="category d-inline-flex font-weight-light">
            Last Campaign Performance
          </p> -->

      <!-- <template slot="actions">
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">updated 10 minutes ago</span>
          </template> -->
      <!--
        </material-chart-card>
      </v-col>
      <v-col
        md="12"
        sm="12"
        lg="4"
      >
        <material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          color="green"
          type="Bar"
        >
          <h3 class="title font-weight-light">
            Completed Tasks
          </h3>
          -->
      <!--
          <p class="category d-inline-flex font-weight-light">
            Last Last Campaign Performance
          </p>

          <template slot="actions">
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">campaign sent 26 minutes ago</span>
          </template>
          -->
      <!--</material-chart-card>
      </v-col>
      <v-col
        sm="6"
        cols="12"
        md="6"
        lg="3"
      >
        <material-stats-card
          color="green"
          icon="mdi-store"
          title="Revenue"
          value="$34,245"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col>
      <v-col
        sm="6"
        cols="12"
        md="6"
        lg="3"
      >
        <material-stats-card
          color="orange"
          icon="mdi-content-copy"
          title="Used Space"
          value="49/50"
          small-value="GB"
          sub-icon="mdi-alert"
          sub-icon-color="error"
          sub-text="Get More Space..."
          sub-text-color="text-primary"
        />
      </v-col>
      <v-col
        sm="6"
        cols="12"
        md="6"
        lg="3"
      >
        <material-stats-card
          :value="totalDeClientes"
          :sub-text="descricaoTotalClientes"
          color="tertiary"
          icon="mdi-account-key"
          title="Total de Clientes"
          sub-icon="mdi-domain"
        />
      </v-col>
      <v-col
        sm="6"
        cols="12"
        md="6"
        lg="3"
      >
        <material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-update"
          sub-text="Just Updated"
        />
      </v-col>
      -->
      <!--
      <v-flex
        md12
        lg6
      >
        <material-card
          color="orange"
          title="Pessoas"
          text="Últimas pessoas movimentadas ou inseridas"
        >
          <v-data-table
            :headers="proximosEventosCabecalho"
            :items="ultimasPessoas"
            :loading="loadingProximosEventos"
            hide-default-footer
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="font-weight-light text-warning text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ index, item }"
            >
              <td
                class="justify-left btnEditar cursorPointer"
                @click="editarPessoa(item)"
              >
                {{ item.nome }}
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </td>
              <td class="hidden-sm-and-down justify-center">
                {{ item.bloco }}
              </td>
              <td class="hidden-sm-and-down justify-center">
                {{ item.apartamento }}
              </td>
              <td class="hidden-md-and-up justify-center">
                {{ item.bloco }} - {{ item.apartamento }}
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        md12
        lg6
      >
        <material-card
          color="orange"
          title="Estados"
          text="Estados do Brasil"
        >
          <v-data-table
            :headers="estadosCabecalho"
            :items="estadoItems"
            :item-key="sigla"
            hide-default-footer
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="font-weight-light text-warning text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ index, item }"
            >
              <td>{{ index + 1 }}</td>
              <td class="text-xs-right">
                {{ item.sigla }}
              </td>
              <td class="text-xs-right">
                {{ item.nome }}
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex> -->
    </v-row>
  </v-container>
</template>

<script>

  import RegionalService from '@/services/RegionalService'
  import PessoasService from '@/services/PessoasService'
  import EventosService from '../services/EventosService'
  import ClientesService from '@/services/ClientesService'
  import colunaEmpresaCliente from "@/utils/EmpresaClienteUtils"
  import DespesasService from '../services/DespesasService'
  import { mapState } from 'vuex'

  export default {
    data () {
      return {
        resumoDespesas: {
          contador: 0,
          valorTotal: 0,
          valorPago: 0,
          valorNaoPago: 0
        },
        totalDeClientes: '0',
        mes: 1,
        ano: 2020,
        dailySalesChart: {
          data: {
            labels: ['SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR'],
            series: [
              [3, 4, 6, 8, 12, 10, 10]
            ]
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0
            }),
            low: 0,
            high: 15, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR'],
            series: [
              [1200, 1600, 2400, 3200, 4800, 4000, 4000]
            ]
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0
            }),
            low: 1000,
            high: 5000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar'],
            series: [
              [1800, 2400, 3600, 4800, 7200, 6000, 6000]
            ]
          },
          options: {
            axisX: {
              showGrid: false
            },
            low: 1500,
            high: 8000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0
            }
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                }
              }
            }]
          ]
        },
        estadosCabecalho: [
          {
            sortable: false,
            text: 'ID',
            value: 'id'
          },
          {
            sortable: false,
            text: 'Sigla',
            value: 'sigla',
            align: 'right'
          },
          {
            sortable: true,
            text: 'Nome',
            value: 'nome',
            align: 'right'
          }
        ],
        estadoItems: [],
        tabs: 0,
        list: {
          0: true,
          1: true,
          2: false
        },
        idEmpresaClientePreenchido: false,
        descricaoTotalClientes: '',
        proximosEventos: [],
        loadingProximosEventos: false
      }
    },
    computed: {
      ...mapState(['idEmpresaCliente']),

      listagemAnos: function () {
        const retorno = []

        for(let i = 0; i < 10; i++){
          retorno.push(2018 + i)
        }

        return retorno
      },

      listagemMeses: function () {
        const retorno = [
          {
            text: this.$i18n.t('padrao_janeiro'),
            value: 0
          },
          {
            text: this.$i18n.t('padrao_fevereiro'),
            value: 1
          },
          {
            text: this.$i18n.t('padrao_marco'),
            value: 2
          },
          {
            text: this.$i18n.t('padrao_abril'),
            value: 3
          },
          {
            text: this.$i18n.t('padrao_maio'),
            value: 4
          },
          {
            text: this.$i18n.t('padrao_junho'),
            value: 5
          },
          {
            text: this.$i18n.t('padrao_julho'),
            value: 6
          },
          {
            text: this.$i18n.t('padrao_agosto'),
            value: 7
          },
          {
            text: this.$i18n.t('padrao_setembro'),
            value: 8
          },
          {
            text: this.$i18n.t('padrao_outubro'),
            value: 9
          },
          {
            text: this.$i18n.t('padrao_novembro'),
            value: 10
          },
          {
            text: this.$i18n.t('padrao_dezembro'),
            value: 11
          }
        ]
        return retorno
      },


      dataPesquisa: function () {
        let filtros = this.listagemMeses.filter(e => {
          return e.value === this.mes
        })
        const retorno = `${filtros[0].text} / ${this.ano}`
        return retorno
      },

      proximosEventosCabecalho: function () {
        const retorno = [
          
          {
            text: this.$i18n.t("EventosForm.tipoEvento"),
            value: "tipoEvento",
            sortable: false,
          },
          /*
          {
            text: this.$i18n.t("EventosForm.nomeCliente"),
            value: "nomeCliente",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.nomeClientePagador"),
            value: "nomeClientePagador",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.dataEvento"),
            value: "dataEvento",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.horaEvento"),
            value: "horaEvento",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.telefone1"),
            value: "telefone1",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          */
        ]

        if (!this.idEmpresaCliente) {
          retorno.push(
            colunaEmpresaCliente(this.$i18n.t("EmpresaClientesForm.tituloPagina"))
          );
        }
        return retorno
      },
    },
    watch: {
      idEmpresaCliente: {
        handler: function(val){
          if(val != 0){
            this.idEmpresaClientePreenchido = true
            this.preencherDados()
          }
        }, 
        immediate: true
      },
      mes(val){
        this.preencherDados()
      },
      ano(val){
        this.preencherDados()
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)
      // this.buscaEstados()
      // this.buscaQuantidadeClientes()
      // this.buscarUltimasPessoas()
      const dataHoje = new Date()
      this.mes = dataHoje.getMonth()
      this.ano = dataHoje.getFullYear()
    },
    methods: {
      preencherDados() {
        if(this.idEmpresaClientePreenchido){
          this.buscarProximosEventos()
          this.preencheResumoDespesas()
        }
      },
      preencheResumoDespesas(){
        DespesasService.somaTotalMes(this.idEmpresaCliente, this.mes, this.ano)
          .then(e => {
            this.resumoDespesas = e.data
          })
      },
      editarPessoa (pessoa) {
        if (pessoa && pessoa.id) {
          const url = '/pessoaForm/' + pessoa.id
          this.$router.push(url)
        }
      },

      editarEvento(item) {
        if (item && item.id) {
          const url = "/eventoForm/" + item.id;
          this.$router.push(url);
        }
      },
      buscarProximosEventos() {
        this.loadingProximosEventos = true
        const pagination = {
          descending: false,
          page: 1,
          itemsPerPage: 10
        }
        const filtro = {}
        const dt = new Date();
        const day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        filtro.dataEvento = `${dt.getFullYear()}-${month}-${day}`
        filtro.idEmpresaCliente = this.idEmpresaCliente
        EventosService.findByParam(filtro, pagination)
          .then((registros) => {
            if (registros && registros.data && registros.data.rows) {
              this.proximosEventos = registros.data.rows;
            }
          })
          .catch((err) => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: "Erro ao buscar os registros: \n" + err,
            })
          })
          .finally(() => {
            this.loadingProximosEventos = false;
          })
      },
      buscarUltimasPessoas () {
        this.loadingUltimasPessoas = true
        const pagination = {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: 'updatedAt'
        }
        PessoasService.findAll(pagination)
          .then((pessoas) => {
            if (pessoas && pessoas.data && pessoas.data.rows) {
              this.ultimasPessoas = pessoas.data.rows
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar as pessoas cadastradas: \n' + err
            })
            this.ultimasPessoas = []
          }).finally(() => {
            this.loadingUltimasPessoas = false
          })
      },
      buscaQuantidadeClientes () {
        ClientesService.countTotalPorEmpresaCliente(this.idEmpresaCliente)
          .then(contador => {
            this.totalDeClientes = '' + contador.data.contador
            if (!this.idEmpresaCliente) {
              this.descricaoTotalClientes = 'Todos os Clientes'
            }
          })
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      buscaEstados () {
        RegionalService.buscaEstados()
          .then(estados => {
            if (!estados || !estados.data) {
              return
            }

            this.estadoItems = estados.data.map(estado => {
              return {
                sigla: estado.sigla,
                nome: estado.nome
              }
            })
          })
      }
    }

  }
</script>
